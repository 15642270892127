import React, {useState} from "react";
import {Create, ImageField, ImageInput, required, SelectInput, SimpleForm, TextInput} from 'react-admin';
import 'resources/style.css';
import {SubmitToolbar} from './SubmitToolbar';
import {DialogLoading} from "custom/DialogLoading";
import {styles} from 'providers/styles';

export const CreateComponent = (props) => {
    const [isOpenLoading, setOpenLoading] = useState(false);
    return (<Create {...props} title='Thêm địa điểm nổi bật'>
            <SimpleForm toolbar={<SubmitToolbar isCreateFrom={true} setOpenLoading={setOpenLoading}/>}>

                <div style={styles.flex}>
                    <div style={styles.singleCol}>
                        <DialogLoading openDialog={isOpenLoading} handleClose={setOpenLoading}/>
                    </div>
                </div>

                <div style={styles.flex}>
                    <div style={styles.singleCol}>
                        <TextInput source="name" label="Tên địa điểm nổi bật" validate={required()}
                                   style={{width: '60rem'}}/>
                    </div>
                </div>


                <div style={styles.flex}>
                    <div style={styles.singleCol}>
                        <SelectInput source="status" label="Trạng thái" style={{width: '29rem'}}
                                     validate={required()} initialValue={true}
                                     choices={[{id: true, name: 'Active'}, {id: false, name: 'InActive'}]}/>
                    </div>
                </div>

                <div style={{width: '60rem'}}>
                    <ImageInput source="img" label="Hình ảnh" style={{width: '60rem'}}
                                accept="image/*" placeholder={<p>Kéo thả ảnh vào đây...</p>}
                                validate={required()} multiple={false}>
                        <ImageField source="src" title="title"/>
                    </ImageInput>
                </div>
            </SimpleForm>
        </Create>)
}