import {Card, CardContent, Chip, Grid, Typography, Box} from '@material-ui/core';
import {Show, TextField, EditButton, TopToolbar, useRecordContext} from 'react-admin';
import Poster from 'custom/Poster';
import DateFieldCustom from 'custom/DateFieldCustom';
import LocationList from './LocationList';
import Basket from "./Basket";

const HotLocationTitle = ({record}) => {
    return <span>{record ? `${record.name}` : ''}</span>;
};

const Spacer = () => <Box mb={1}>&nbsp;</Box>;

const HotLocationForm = () => {
    const record = useRecordContext();
    return (
        <Box maxWidth="50em">
            <Card>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={9}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Poster/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Typography variant="h6" gutterBottom>
                                        {record.name} {record.status ?
                                        <Chip label="Active" style={{backgroundColor: '#6fbf73', color: '#fff'}}/> :
                                        <Chip label="InActive"
                                              style={{backgroundColor: '#ff1744', color: '#fff'}}/>}
                                    </Typography>
                                    <Typography gutterBottom>
                                        Số Phòng: <TextField source="totalRoom" label='Số phòng'/>
                                    </Typography>
                                    <Typography gutterBottom>
                                        Ngày Tạo: <DateFieldCustom record={record} source="createdDateTime"
                                                                   format="MM/DD/YYYY"/>
                                    </Typography>
                                    <Typography gutterBottom>
                                        Ngày Sửa: <DateFieldCustom record={record} source="updatedDateTime"
                                                                   format="MM/DD/YYYY"/>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Spacer/>

                            <Typography variant="h6" gutterBottom>Danh sách địa chỉ</Typography>
                            <div>
                                <Basket />
                            </div>
                            <Spacer/>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );
};


const ShowShowActions = ({basePath, data, resource}) => (
    <Box maxWidth="50em">
        <TopToolbar>
            <EditButton basePath={basePath} record={data} label={"Cập nhật địa điểm nổi bật"}/>
        </TopToolbar>
    </Box>
);


export const ShowComponent = (props) => {
    return (
        <Show component="div" actions={<ShowShowActions/>} title={<HotLocationTitle/>} {...props} >
            <HotLocationForm {...props} />
        </Show>
    )
}