// in src/dataProvider
import {
    CREATE,
    DELETE,
    DELETE_MANY,
    fetchUtils,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    UPDATE,
} from "react-admin";
import moment from "moment";
import {stringify} from "query-string";
import {getApiByResource, getBackendUrl, getHeader} from "./utils";

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
    const options = getHeader();
    const backendUrl = getBackendUrl(resource);
    const apiPath = getApiByResource(resource);
    switch (type) {
        case GET_LIST: {
            const {page, perPage} = params.pagination;
            const {field, order} = params.sort;
            const {filter} = params;

            // Remove fulltext search
            delete filter.q;

            const query = {
                filter: JSON.stringify(filter),
                page: page,
                size: perPage,
                sort: `${field},${order}`,
            };

            if (resource === "location") {
                let filter = JSON.parse(query.filter);
                // if (filter.paymentModeId) {
                //     filter.paymentMode = new Object({ id: filter.paymentModeId });
                // }
                // delete filter.paymentModeId;
                // query.filter = JSON.stringify(filter);
            }

            let url = `${backendUrl}/${apiPath}?${stringify(query)}`;
            return {url, options};
        }
        case "GET_TOTAL": {
            return {url: `${backendUrl}/${apiPath}/count`, options};
        }
        case GET_ONE:
            return {
                url: `${backendUrl}/${apiPath}/${params.id}`,
                options,
            };
        case GET_MANY: {
            const query = {
                filter: JSON.stringify({where: {id: {inq: params.ids}}}),
            };
            return {url: `${backendUrl}/${apiPath}?${stringify(query)}`, options};
        }
        case GET_MANY_REFERENCE: {
            const {page, perPage} = params.pagination;
            const {field, order} = params.sort;
            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                filter: JSON.stringify({
                    ...params.filter,
                    [params.target]: params.id,
                }),
            };
            return {url: `${backendUrl}/${apiPath}?${stringify(query)}`};
        }
        case UPDATE: {
            Object.keys(params.data).forEach(
                (k) => params.data[k] == undefined && delete params.data[k]
            );
            params.data.user &&
            Object.keys(params.data.user).forEach(
                (k) => params.data.user[k] == undefined && delete params.data.user[k]
            );
            if (!resource.toString().includes('transaction')) {
                params.data.status = params.data.status ? 1 : 0;
            }
            return {
                url: `${backendUrl}/${apiPath}/update/${params.id}`,
                options: {
                    ...options,
                    method: "POST",
                    body: JSON.stringify(params.data),
                },
            };
        }
        case CREATE: {
            switch (resource) {
                default:
                    break;
            }
            return {
                url: `${backendUrl}/${apiPath}`,
                options: {
                    ...options,
                    method: "POST",
                    body: JSON.stringify(params.data),
                },
            };
        }
        case DELETE: {
            let body = {};
            switch (resource) {
                case "deal-rules":
                    body.actionBy = localStorage.getItem("username");
                    break;
                default:
                    break;
            }
            return {
                url: `${backendUrl}/${apiPath}/delete/${params.id}`,
                options: {...options, method: "POST", body: JSON.stringify(body)},
            };
        }
        case DELETE_MANY: {
            const query = {
                id: params.ids,
            };
            return {
                url: `${backendUrl}/${apiPath}/delete?${stringify(query)}`,
                options: {...options, method: "POST"},
            };
        }
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
    }
};

const convertToLocalTime = (isoTime) =>
    isoTime ? moment.utc(isoTime).local().format("YYYY/MM/DD HH:mm:ss") : isoTime;

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (
    response,
    type,
    resource,
    params) => {
    const {json} = response;
    let {data, total} = json;

    try {
        const formatedData = data.map((item) => {
            const {key, id} = item;
            if (!id && key) {
                return {...item, id: key};
            }
            return item;
        });
    } catch (err) {
    }

    switch (type) {
        case GET_LIST:
            let indexStart = (params.pagination.page - 1) * params.pagination.perPage + 1;
            let cookedTimeData = data.map((m) => {
                switch (resource) {
                    default:
                        break;
                }
                m.idx = indexStart;
                if (!resource.toString().includes('transaction')) {
                    m.status = m.status === "1" || m.status === true;
                }
                indexStart++;
                return m;
            });
            let dataResponse = {
                data: cookedTimeData,
                total: total,
            };
            return dataResponse;
        case CREATE:
            let myData = {...params.data, id: json.id};
            return {data: myData};
        case DELETE:
            return {data: {id: params.id}};
        case DELETE_MANY:
            return {data: params.ids};
        case UPDATE:
            return {
                data: {id: params.id},
            };
        default:
            return {data: json.data};
    }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param params
 * @returns {Promise} the Promise for response
 */
export default (type, resource, params) => {
    const {fetchJson} = fetchUtils;
    if (params && params.pagination && params.pagination.page) {
        params.pagination.page = params.pagination.page - 1;
    }
    const {url, options} = convertDataProviderRequestToHTTP(
        type,
        resource,
        params
    );

    return fetchJson(url, options).then((response) => {
        const {status} = response;


        let dataResponse = {
            json: [],
        };

        let total = 0;

        switch (resource) {
            default:
                dataResponse = response;
                total = response.json.total || 0;
                break;
        }

        return convertHTTPResponseToDataProvider(
            dataResponse,
            type,
            resource,
            params,
            total
        );
    }).catch((err) => {
        console.log("Has an error when call api: ", err)
        // if (err.status === 401 || !err.status) {
        //     localStorage.removeItem('authToken');
        //     localStorage.removeItem('username');
        //     localStorage.removeItem('roleName');
        //     window.location.reload();
        // }
    });
};
