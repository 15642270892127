import {useFormState} from "react-final-form";
import {fetchUtils, SaveButton, Toolbar, useRedirect} from "react-admin";
import {addUploadFeature, getApiByResource, getBackendUrl, slugify, getFileExtension} from "providers/utils";
import CancelButton from "custom/CancelButton";
import React from "react";

const {fetchJson} = fetchUtils;
const backendUrl = getBackendUrl();
let apiPath = getApiByResource('room');

export const ProductSubmitToolbar = props => {
    const fromState = useFormState();
    const {values} = fromState;
    const {images} = values;
    const {isCreateFrom, setOpenLoading} = props;
    const redirect = useRedirect();
    let saveBtnLabel = "Thêm Sản Phẩm";
    if (!isCreateFrom) {
        saveBtnLabel = "Cập nhật Sản Phẩm"
    }


    const fileImagesProceed = async () => {
        let imageFiles = [];
        for (let image of images) {
            await addUploadFeature(image).then(r => {
                imageFiles.push(r);
            })
        }
        return imageFiles;
    }

    const onSaveRoom = () => {
        values.status = values.status ? 1 : 0;
        const formData = new FormData();
        let productKey = Object.keys(values);
        if (productKey.length === 0) {
            console.log("dont have any data to update")
            return;
        }

        console.log("product submit data", values)
        let imgKey = "imageRequest";
        if (!isCreateFrom) {
            imgKey = "images";
        }

        for (let key in values) {
            if (key === 'createdDateTime') continue;
            if (key === 'updatedDateTime') continue;
            if (key === imgKey) continue; // ignore img key to process later
            if (key === 'category') {
                formData.append('categoryId', values[key].id);
                continue;
            }
            formData.append(key, values[key]);
        }

        if (isCreateFrom) { // case create form
            for (let [index, imgItem] of values[imgKey].entries()) {
                if (imgItem.rawFile) {
                    formData.append("imageRequest[]", imgItem.rawFile,
                        `${slugify(values['productName'])}-${index}.${getFileExtension(imgItem.title)}`);
                }
            }
        } else { // case update form
            for (let [index, imgItem] of values[imgKey].entries()) {
                if (imgItem.rawFile) { // add new img
                    formData.append("imageRequest[]", imgItem.rawFile,
                        `${slugify(values['productName'])}-${index}.${getFileExtension(imgItem.title)}`);
                } else {
                    formData.append("productImagesUpdateRequest[]", imgItem.image);
                }
            }
        }

        const token = localStorage.getItem('authToken');
        const options = {
            headers: new Headers({
                Authorization: 'Bearer ' + token,
            }),
            method: 'POST',
            body: formData
        };

        if (isCreateFrom) {
            apiPath = getApiByResource('product') + '/create-product';
        } else {
            apiPath = getApiByResource('product') + '/update-product';
        }
        setOpenLoading(true);
        fetchJson(`${backendUrl}/${apiPath}`, options)
            .then(
                response => {
                    setOpenLoading(false);
                    redirect(props.basePath);
                }, error => {
                    console.info("Error when call api to server: ", error);
                    setOpenLoading(false);
                });
    }

    return (
        <Toolbar {...props} >
            <SaveButton label={saveBtnLabel} onSave={onSaveRoom}/>
            <CancelButton redirect="list"/>
        </Toolbar>
    )
};