import {useFormState} from "react-final-form";
import {fetchUtils, SaveButton, Toolbar, useRedirect} from "react-admin";
import {addUploadFeature, getApiByResource, getBackendUrl, getFileExtension, slugify} from "providers/utils";
import CancelButton from "custom/CancelButton";
import React from "react";

const {fetchJson} = fetchUtils;
const backendUrl = getBackendUrl();
let apiPath = getApiByResource('room');

export const SubmitToolbar = props => {
    const fromState = useFormState();
    const {values} = fromState;
    const {images} = values;
    const {isCreateFrom, setOpenLoading} = props;
    const redirect = useRedirect();
    let saveBtnLabel = "Tạo kho";
    if (!isCreateFrom) {
        saveBtnLabel = "Cập nhật kho"
    }


    const fileImagesProceed = async () => {
        let imageFiles = [];
        for (let image of images) {
            await addUploadFeature(image).then(r => {
                imageFiles.push(r);
            })
        }
        return imageFiles;
    }

    const onSaveRoom = () => {
        values.status = values.status ? 1 : 0;
        const formData = new FormData();
        let roomKey = Object.keys(values);
        console.log("roomKey", values)

        let keyImageIgnore = "images";
        if (!isCreateFrom) {
            keyImageIgnore = "propertyImages";
        }

        if (roomKey.length !== 0) {
            for (let key in values) {
                if (key !== keyImageIgnore) {
                    if (key === 'location') {
                        formData.append('locationId', values[key].id);
                    } else {
                        formData.append(key, values[key]);
                    }
                }
            }

            if (isCreateFrom) {
                for (let [index, image] of values[keyImageIgnore].entries()) {
                    if (image.rawFile) {
                        formData.append("images[]", image.rawFile,
                            `${slugify(values['propertyTitle'])}-${index}.${getFileExtension(image.title)}`);
                    }
                }
            } else {
                for (let [index, image] of values[keyImageIgnore].entries()) {
                    if (image.rawFile) {
                        formData.append("images[]", image.rawFile,
                            `${slugify(values['propertyTitle'])}-${index}.${getFileExtension(image.title)}`);
                    } else {
                        formData.append("propertyImages[]", image.propertyImage);
                    }
                }
            }
        }

        const token = localStorage.getItem('authToken');
        const options = {
            headers: new Headers({
                Authorization: 'Bearer ' + token,
            }),
            method: 'POST',
            body: formData
        };

        if (isCreateFrom) {
            apiPath = getApiByResource('warehouse') + '/create-warehouse';
        } else {
            apiPath = getApiByResource('warehouse') + '/update-warehouse';
        }
        setOpenLoading(true);
        fetchJson(`${backendUrl}/${apiPath}`, options)
            .then(
                response => {
                    setOpenLoading(false);
                    redirect(props.basePath);
                }, error => {
                    console.info("Error when call api to server: ", error);
                    setOpenLoading(false);
                });
    }

    return (
        <Toolbar {...props} >
            <SaveButton label={saveBtnLabel} onSave={onSaveRoom}/>
            <CancelButton redirect="list"/>
        </Toolbar>
    )
};