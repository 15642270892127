import React from 'react';
import {BooleanInput, Edit, required, SaveButton, SimpleForm, TextInput, Toolbar} from 'react-admin';
import CancelButton from "../../../custom/CancelButton";

const ToolbarEdit = (props) => {
    return (
        <Toolbar {...props} >
            <SaveButton label="Lưu"/>
            <CancelButton redirect="list"/>
        </Toolbar>
    )
}

export const RoomTypeEdit = props => (
    <Edit title="Cập nhật loại phòng" {...props} >
        <SimpleForm toolbar={<ToolbarEdit/>}>
            <TextInput source="roomTypeName" label={'Loại phòng'} validate={required()}/>
            <BooleanInput source='status' label="Status"/>
        </SimpleForm>
    </Edit>
);