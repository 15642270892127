import * as React from "react";
import {
    CreateButton,
    Datagrid,
    EditButton,
    ExportButton,
    FunctionField,
    List,
    TextField,
    TopToolbar
} from "react-admin";
import Chip from '@material-ui/core/Chip';
import {formatMoney} from "providers/utils";
import PropertyTypeConst from "providers/PropertyTypeConst";

const ListActions = (props) => (
    <TopToolbar>
        <CreateButton label={"Tạo Văn Phòng"}/>
        <ExportButton label={"Xuất File"}/>
    </TopToolbar>
);

export const OfficeList = (props) => {
    return (
        <List {...props} bulkActionButtons={false} title='Danh sách Văn Phòng'
              filter={{location: {locationType: PropertyTypeConst.OFFICE_FOR_RENT.key}}}
              actions={<ListActions/>}>
            <Datagrid>
                <EditButton label={""}/>
                <TextField source="propertyTitle" label='Tên Kho'/>
                <TextField source="location.fullLocationName" label='Địa chỉ'/>
                <FunctionField label="Giá" render={record => {
                    if (!record.propertyPrice) {
                        return 'Liên Hệ';
                    }

                    let priceType = record.priceType;
                    if (record.priceTypeCustom) {
                        priceType = record.priceTypeCustom;
                    }
                    return formatMoney(record.propertyPrice) + '/' + priceType;
                }}/>
                <TextField source="propertySquare" label='Diện tích'/>
                <TextField source="roomAmenityType" label='Nội Thất'/>
                <FunctionField label="Trạng thái" render={record => {
                    return record.status ?
                        <Chip label="Cho thuê" style={{backgroundColor: '#6fbf73', color: '#fff'}}/> :
                        <Chip label="Không cho thuê" style={{backgroundColor: '#ff1744', color: '#fff'}}/>;
                }}/>
            </Datagrid>
        </List>
    )
};

