import * as React from "react";
import {Datagrid, EditButton, FunctionField, List, TextField} from "react-admin";
import DateFieldCustom from "custom/DateFieldCustom";
import {ADMINISTRATOR_ROLE} from 'providers/consts';
import Chip from '@material-ui/core/Chip';

export const UserList = (props) => {
    const currentRole = localStorage.getItem("roleName");

    return (
        currentRole && currentRole === ADMINISTRATOR_ROLE &&
        <List {...props} bulkActionButtons={false} title='Danh sách người dùng'>
            <Datagrid>
                <TextField source="id"/>
                <TextField source="username" label='Tên Người dùng'/>
                <TextField source="address" label='Địa chỉ'/>
                <DateFieldCustom source="dob" label="Ngày sinh" format="YYYY-DD-MMM"/>
                <FunctionField label="Trạng thái" render={record => {
                    return record.status == true ?
                        <Chip label="ACTIVE" style={{backgroundColor: '#6fbf73', color: '#fff'}}/> :
                        <Chip label="INACTIVE" style={{backgroundColor: '#ff1744', color: '#fff'}}/>;
                }}/>
            </Datagrid>
        </List>
    )
};

