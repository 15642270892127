import * as React from "react";
import {
    ArrayField,
    BooleanField,
    Datagrid,
    EmailField,
    FunctionField,
    ImageField,
    RichTextField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
    UrlField
} from 'react-admin';


const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '5%'},
    rightCol: {flex: 1, marginLeft: '5%'},
    singleCol: {marginTop: '1em', marginBottom: '1em'},
};


export const ShowInformation = (props) => (
    <Show {...props} title={"Quản lý thông tin hệ thống TaydoSpace"}>

        <TabbedShowLayout>
            <Tab label="Thông tin cơ bản">
                <TextField source="title" label={"Tiêu đề"}/>
                <TextField source="hotline" label={"Hotline"}/>
                <EmailField source="email" label={"Email"}/>
                <UrlField source="website" label={"Website"}/>
                <RichTextField source="description" label='Giới thiệu'/>
            </Tab>
            <Tab label="Chi nhánh" path="chi-nhanh">
                <ArrayField source="companyBranches" label={""}>
                    <Datagrid>
                        <TextField source="address" label={"Địa chỉ"}/>
                        <BooleanField source="headOffice" label="Trụ sở"/>
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="CMS" path="cms">
                <h4>Header configuration</h4>
                <FunctionField label="Tiêu đề 1" render={record => record.showHeaderOne === true &&
                    <>
                        <BooleanField label="Hiển thị tiêu đề 1" source="showHeaderOne"/>
                        <ImageField source="imageHeaderOne" title="title"/>
                    </>}
                />

                <FunctionField label="Tiêu đề 2" render={record => record.showHeaderTwo === true &&
                    <>
                        <BooleanField label="Hiển thị tiêu đề 1" source="showHeaderTwo"/>
                        <ImageField source="imageHeaderTwo" title="title"/>
                    </>}
                />

            </Tab>
        </TabbedShowLayout>
    </Show>
);