import {useFormState} from "react-final-form";
import {fetchUtils, SaveButton, Toolbar, useRedirect} from "react-admin";
import {getApiByResource, getBackendUrl, getFileExtension, slugify} from "providers/utils";
import CancelButton from "custom/CancelButton";
import React from "react";

const {fetchJson} = fetchUtils;
const backendUrl = getBackendUrl();
let apiPath = getApiByResource('room');

export const SubmitToolbar = props => {
    const fromState = useFormState();
    const {values} = fromState;
    const {images} = values;
    const {isCreateFrom, setOpenLoading} = props;
    const redirect = useRedirect();
    let saveBtnLabel = "Tạo địa điểm";
    if (!isCreateFrom) {
        saveBtnLabel = "Cập nhật địa điểm"
    }

    const onSaveRoom = () => {
        values.status = values.status ? 1 : 0;
        const formData = new FormData();
        let hotLocationObjects = Object.keys(values);
        console.log("hotLocationObjects", values)

        let keyImageIgnore = "img";
        if (!isCreateFrom) {
            keyImageIgnore = "hotLocationImg";
        }

        if (hotLocationObjects.length !== 0) {
            for (let key in values) {
                if (key !== keyImageIgnore) {
                    if (key === 'location') {
                        formData.append('locationId', values[key].id);
                    } else {
                        formData.append(key, values[key]);
                    }
                }
            }


            let image = values[keyImageIgnore]
            if (isCreateFrom) {
                if (image.rawFile) {
                    formData.append("img", image.rawFile,
                        `${slugify(values['name'])}.${getFileExtension(image.title)}`);
                }
            } else {
                if (image.rawFile) {
                    formData.append("img", image.rawFile,
                        `${slugify(values['name'])}.${getFileExtension(image.title)}`);
                } else {
                    formData.append("hotLocationImg", image.hotLocationImg);
                }
            }
        }

        const token = localStorage.getItem('authToken');
        const options = {
            headers: new Headers({
                Authorization: 'Bearer ' + token,
            }),
            method: 'POST',
            body: formData
        };

        if (isCreateFrom) {
            apiPath = getApiByResource('hot-location') + '/create-hot-location';
        } else {
            apiPath = getApiByResource('hot-location') + '/update-hot-location';
        }
        setOpenLoading(true);
        fetchJson(`${backendUrl}/${apiPath}`, options)
            .then(
                response => {
                    setOpenLoading(false);
                    redirect(props.basePath);
                }, error => {
                    console.info("Error when call api to server: ", error);
                    setOpenLoading(false);
                });
    }

    return (
        <Toolbar {...props} >
            <SaveButton label={saveBtnLabel} onSave={onSaveRoom} />
            <CancelButton redirect="list"/>
        </Toolbar>
    )
};