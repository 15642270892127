import * as React from 'react';
import {useState} from 'react';
import {
    BooleanInput,
    Edit,
    FormDataConsumer,
    ImageField,
    ImageInput,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'react-admin';
import {ProductSubmitToolbar} from "./ProductSubmitToolbar";
import {DialogLoading} from "custom/DialogLoading";
import {RichTextInput} from "ra-richtext-tiptap";


const ProductTitle = ({record}) => record ? <span>Sản Phẩm #{record.productName}</span> : null;

export const styles = {
    price: {width: '7em'},
    width: {width: '7em'},
    height: {width: '7em'},
    stock: {width: '7em'},
    widthFormGroup: {display: 'inline-block'},
    heightFormGroup: {display: 'inline-block', marginLeft: 32},
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '5%'},
    rightCol: {flex: 1, marginLeft: '5%'},
    singleCol: {marginTop: '1em', marginBottom: '1em'},
};

const ProductEdit = (props) => {
    const [isOpenLoading, setOpenLoading] = useState(false);
    return (
        <Edit {...props} title={<ProductTitle/>}>
            <SimpleForm toolbar={<ProductSubmitToolbar isCreateFrom={false} setOpenLoading={setOpenLoading}/>}>
                <DialogLoading openDialog={isOpenLoading} handleClose={setOpenLoading}/>
                <h3>Thông tin Sản Phẩm</h3>

                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <TextInput source="productName" label="Tên Sản Phẩm" validate={required()}
                                   style={{width: '19rem'}}/>
                    </div>
                    <div style={styles.rightCol}>
                        <ReferenceInput source="category.id" label="Danh Mục" reference="category"
                                             filter={{status: true}}
                                             style={{width: '19rem'}}>
                            <SelectInput optionText="categoryName" optionValue={"id"} style={{width: '19rem'}}
                                         validate={required()}/>
                        </ReferenceInput>
                    </div>
                </div>

                <div style={styles.flex}>
                    <div style={styles.singleCol}>
                        <RichTextInput source="description" style={{width: '29.5rem'}}
                                       validate={[required()]} label='Giới thiệu Sản Phẩm'/>
                    </div>
                </div>
                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <NumberInput label="Giá bán" source="price" min={0}
                                     style={{width: '14rem'}}
                                     validate={required()}/>
                    </div>
                    <div style={styles.rightCol}>
                        <NumberInput label="Số lượng trong kho" source="stockQuantity" min={0}
                                     style={{width: '14rem'}}
                                     validate={required()}/>
                    </div>
                    <div style={styles.rightCol}>
                        <SelectInput source="status" label="Trạng thái" style={{width: '10rem'}}
                                     validate={required()} initialValue={true} choices={[
                            {id: true, name: 'Bán'},
                            {id: false, name: 'Không bán'}
                        ]}/>
                    </div>
                </div>
                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <BooleanInput source="isNew" label="Sản Phẩm mới" style={{width: '19rem'}}/>
                    </div>
                    <div style={styles.rightCol}>
                        <BooleanInput source="isHot" label="Sản Phẩm Hot" style={{width: '19rem'}}/>
                    </div>
                </div>

                <div style={{width: '42rem'}}>
                    <ImageInput source="images" label="Cập nhật hình" style={{width: '60rem'}}
                                accept="image/*" placeholder={<p>Kéo thả ảnh của bạn vào đây...</p>}
                                validate={required()} multiple={true}>
                        <ImageField source="image" title="title"/>
                    </ImageInput>
                </div>
            </SimpleForm>
        </Edit>
    );
};

export default ProductEdit;