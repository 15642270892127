import React from 'react';
import {Loading} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

export const DialogLoading = (props) => {
    const { openDialog } = props;
    return (
        <Dialog open={openDialog} onClose={props.handleClose} aria-labelledby="form-dialog-title" disableBackdropClick disableEscapeKeyDown>
            <DialogContent>
                <Loading loadingSecondary="Please wait a moment" />
            </DialogContent>
        </Dialog>
    )
};