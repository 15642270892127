export const roomTypes = [
    {id: "Phòng gác lửng", name: "Phòng gác lửng"},
    {id: "Phòng Studio", name: "Phòng Studio"},
    {id: "Căn hộ phòng ngủ riêng", name: "Căn hộ phòng ngủ riêng"},
    {id: "Căn hộ dịch vụ", name: "Căn hộ dịch vụ"},
    {id: "Nhà Nguyên Căn", name: "Nhà Nguyên Căn"},
];

export const roomAmenityTypes = [
    {id: "Đầy đủ nội thất", name: "Đầy đủ nội thất"},
    {id: "Nội thất cơ bản", name: "Nội thất cơ bản"},
    {id: "Không nội thất", name: "Không nội thất"},
];


export const roomPriceTypes = [
    {id: "Giờ", name: "Giờ"},
    {id: "Ngày", name: "Ngày"},
    {id: "Tháng", name: "Tháng"},
    {id: "Năm", name: "Năm"},
    {id: "Tuỳ chỉnh", name: "Tuỳ chỉnh"},
];


export const priceTypeRequire = (value, allValues) => {
    if (allValues.propertyPrice && !value) {
        return 'Required field';
    }
    return undefined;
};

export const priceTypeCustomRequire = (value, allValues) => {
    if (!value && allValues.propertyPrice && allValues.priceType && allValues.priceType === 'Tuỳ chỉnh') {
        return 'Required field';
    }
    return undefined;
};