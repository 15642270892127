import React, {useMemo, useState} from "react";
import {
    BooleanInput,
    Create,
    ReferenceInput,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar
} from 'react-admin';
import addressData from 'resources/address-vietnam.json';
import addressVietnamV2 from 'resources/address-vietnam-v2.json';
import CancelButton from "custom/CancelButton";
import {getAddressVietnam, getDistrictsByProvinceName, getWardByDistrictName} from "providers/utils";
import {locationTypeChoices} from "./LocationConst";
import {styles} from 'providers/styles';

let dataAddress = JSON.parse(JSON.stringify(addressData));
const addressVietnam = getAddressVietnam(addressVietnamV2);
const provinces = addressVietnam && addressVietnam.map(address => {
    return {id: address.name, name: address.name}
});

let dataDistrictObjs = {};

const ToolbarCreate = (props) => {
    return (
        <Toolbar {...props} >
            <SaveButton label="Tạo mới"/>
            <CancelButton redirect="list"/>
        </Toolbar>
    )
}

export const CreateLocation = (props) => {
    const { source, ...rest } = props;
    const [locationProvinces, setLocationProvinces] = useState(provinces);
    const [provinceNameSelected, setProvinceNameSelected] = useState('Thành phố Hồ Chí Minh'); // hochiminh code

    const [locationDistricts, setLocationDistricts] = useState([]);
    const [districtNameSelected, setDistrictNameSelected] = useState('Quận 1'); // district 1

    const [locationWards, setLocationWards] = useState([]);

    useMemo(() => {
        if (provinceNameSelected && provinceNameSelected !== '') {
            dataDistrictObjs = getDistrictsByProvinceName(addressVietnam, provinceNameSelected);
            let districts = dataDistrictObjs.map(obj => {
                return {id: obj.name, name: obj.name};
            });
            setLocationDistricts(districts);
        }
    }, [provinceNameSelected]);

    useMemo(() => {
        if (districtNameSelected) {
            let dataDistricts = getWardByDistrictName(dataDistrictObjs, districtNameSelected);
            let wards = dataDistricts.map(obj => {
                return {id: obj.name, name: obj.name};
            });
            setLocationWards(wards);
        }
    }, [districtNameSelected]);

    return (
        <Create {...props} title='Tạo Địa Chỉ'>
            <SimpleForm toolbar={<ToolbarCreate/>} redirect="show">
                <h3>Thông tin địa chỉ</h3>
                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <TextInput source="locationName" validate={[required()]} label='Tên địa chỉ'
                                   style={{width: '19rem'}}/>
                    </div>

                    <div style={styles.rightCol}>
                        <SelectInput source="locationType" label="Loại"
                                     choices={locationTypeChoices} validate={required()} style={{width: '19rem'}}/>

                    </div>

                    <div style={styles.rightCol}>
                        <ReferenceInput source="hotLocationId" label="Địa điểm nổi bật" reference="hot-location"
                                        filter={{status: true}}
                                        style={{width: '19rem'}}>
                            <SelectInput optionText="name" optionValue={"id"} style={{width: '19rem'}}/>
                        </ReferenceInput>
                    </div>
                </div>

                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <SelectInput source="locationProvince" validate={[required()]}
                                     choices={locationProvinces}
                                     label='Tỉnh/Thành phố'
                                     onChange={(e) => {
                                         let {target} = e;
                                         let {value} = target;
                                         if (value) {
                                             setProvinceNameSelected(value);
                                         }
                                     }} style={{width: '19rem'}}/>
                    </div>

                    <div style={styles.rightCol}>
                        <SelectInput source="locationDistrict" validate={[required()]} choices={locationDistricts}
                                     label='Quận/Huyện' onChange={(e) => {
                            let {target} = e;
                            let {value} = target;
                            if (value) {
                                setDistrictNameSelected(value);
                            }
                        }} style={{width: '19rem'}}/>

                    </div>

                    <div style={styles.rightCol}>
                        <SelectInput source="locationWard" validate={[required()]} choices={locationWards}
                                     label='Xã/Phường'
                                     style={{width: '19rem'}}/>
                    </div>
                </div>

                <div style={styles.flex}>
                    <div style={styles.singleCol}>
                        <TextInput source="locationAddress" validate={[required()]} label='Địa chỉ'
                                   style={{width: '60rem'}}/>
                    </div>
                </div>

                <div style={styles.flex}>
                    <div style={styles.singleCol}>
                        <TextInput source="mapEmbed" label='Vị Trí' style={{width: '60rem'}}
                                   multiline
                                   rows={4}/>
                    </div>
                </div>

                {/*<ReferenceInput source="locationType.id" label="Loại địa chỉ" reference="location-type"*/}
                {/*                style={{width: '500px'}}>*/}
                {/*    <SelectInput optionText="locationTypeName" optionValue={"id"} style={{width: '500px'}}*/}
                {/*                 validate={required()}/>*/}
                {/*</ReferenceInput>*/}


                <BooleanInput source="status" initialValue={true} label='Trạng thái'/>
            </SimpleForm>
        </Create>
    )
}