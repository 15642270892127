import * as React from 'react';
import {Box, Chip, useMediaQuery} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {
    CreateButton,
    ExportButton,
    FilterButton,
    FilterContext,
    FilterForm,
    ListBase,
    NumberInput,
    Pagination,
    ReferenceInput,
    SearchInput,
    SelectInput,
    SortButton,
    Title,
    TopToolbar,
    useListContext,
    useTranslate,
} from 'react-admin';

import GridList from './GridList';
import {Aside} from './Aside';

const useQuickFilterStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(1),
    },
}));

const QuickFilter = ({label}) => {
    const translate = useTranslate();
    const classes = useQuickFilterStyles();
    return <Chip className={classes.root} label={translate(label)}/>;
};

export const productFilters = [
    <SearchInput source="q" alwaysOn/>,
    <ReferenceInput
        source="category_id"
        reference="categories"
        sort={{field: 'id', order: 'ASC'}}
        label={"123"}
    >
        <SelectInput source="name"/>
    </ReferenceInput>,
    <NumberInput source="height_lte"/>,
    <QuickFilter
        label="Số lượng"
        source="stock_lte"
        defaultValue={10}
    />
];

const ListActions = ({isSmall}) => (<TopToolbar>
    {isSmall && <FilterButton/>}
    <SortButton fields={['productName', 'price', 'stockQuantity']} label={"Sắp xếp theo"}/>
    <CreateButton label={"Thêm Sản Phẩm"} basePath="/product"/>
    <ExportButton label={"Xuất File"}/>
</TopToolbar>);

const ProductList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (<ListBase
        perPage={20}
        sort={{field: 'productName', order: 'ASC'}}
        {...props}
    >
        <ProductListView isSmall={isSmall}/>
    </ListBase>);
};

const ProductListView = ({isSmall}) => {
    const {defaultTitle} = useListContext();
    return (<>
        <Title defaultTitle={"Sản Phẩm"}/>
        <FilterContext.Provider value={productFilters}>
            <ListActions isSmall={isSmall}/>
            {isSmall && (<Box m={1}>
                <FilterForm/>
            </Box>)}
        </FilterContext.Provider>
        <Box display="flex">
            <Aside/>
            <Box width={isSmall ? 'auto' : 'calc(100% - 16em)'}>
                <GridList/>
                <Pagination rowsPerPageOptions={[10, 20, 40]}/>
            </Box>
        </Box>
    </>);
};
export default ProductList;