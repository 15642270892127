import React from 'react';
import {BooleanInput, Create, required, SaveButton, SimpleForm, TextInput, Toolbar} from 'react-admin';
import CancelButton from "custom/CancelButton";

const ToolbarCreate = (props) => {
    return (
        <Toolbar {...props} >
            <SaveButton label="Tạo mới"/>
            <CancelButton redirect="list"/>
        </Toolbar>
    )
}


export const FeatureCreate = props => (
    <Create title="Tạo Tiện Ích"  {...props} >
        <SimpleForm toolbar={<ToolbarCreate/>}>
            <TextInput source="featureName" label={"Tiện Nghi"} validate={required()}/>
            <BooleanInput source='status' label="Trạng thái" initialValue={true}/>
        </SimpleForm>
    </Create>
);