import React from "react";
import {Admin, Resource} from "react-admin";
import englishMessages from "./i18n/en";
import {Layout, Login} from "./layout";
import polyglotI18nProvider from "ra-i18n-polyglot";

import authProvider from "./providers/authProvider";
import dataProvider from "./providers/dataProvider";
import customRoutes from "./routes";
import themeReducer from "./themeReducer";
import user from "screens/UserManagement/user";
import role from "./screens/UserManagement/role";
import room from "./screens/PropertyMgmt/RoomManagement";
import location from "./screens/LocationManagerment";
import amenity from './screens/UtilizeManagement/AmenityManagement';
import feature from './screens/UtilizeManagement/FeatureManagement';
import roomTypeComponent from './screens/UtilizeManagement/RoomTypeMgmt';
import warehouse from 'screens/PropertyMgmt/WarehouseManagement'
import car from 'screens/PropertyMgmt/CarManagement'
import applicationInformationMgmt from "./screens/ApplicationInformationMgmt";
import hotLocationManagement from "./screens/HotLocationMgmt";
import officeManagement from "./screens/PropertyMgmt/OfficeManagement";
import productMgmt from "./screens/ProductMgmt/Product";
import categoryMgmt from "./screens/ProductMgmt/CategoryMgmt";
import "./App.css?version=1.0";

const i18nProvider = polyglotI18nProvider((locale) => {
    return englishMessages;
}, "en");

const App = () => {
    if (!dataProvider) {
        return (
            <div className="loader-container">
                <div className="loader">Loading...</div>
            </div>
        );
    }
    return (
        <>
            <Admin
                title="Tây Đô Portal"
                dataProvider={dataProvider}
                customReducers={{theme: themeReducer}}
                customRoutes={customRoutes}
                authProvider={authProvider}
                // dashboard={Dashboard}
                loginPage={Login}
                layout={Layout}
                i18nProvider={i18nProvider}
            >
                <Resource
                    name="location"
                    options={{label: "Location"}}
                    list={location.list}
                    edit={location.edit}
                    create={location.create}
                />
                <Resource
                    name="room"
                    options={{label: "Room"}}
                    list={room.list}
                    edit={room.edit}
                    create={room.create}
                />
                <Resource
                    name="amenity"
                    options={{label: "Amenity"}}
                    list={amenity.list}
                    edit={amenity.edit}
                    create={amenity.create}
                />
                <Resource
                    name="feature"
                    options={{label: "Feature"}}
                    list={feature.list}
                    edit={feature.edit}
                    create={feature.create}
                />
                <Resource
                    name="user"
                    options={{label: "User"}}
                    list={user.list}
                    edit={user.edit}
                />
                <Resource
                    name="role"
                    options={{label: "Role"}}
                    list={role.list}
                    edit={role.edit}
                    create={role.create}
                />

                <Resource
                    name="warehouse"
                    options={{label: "Warehouse"}}
                    list={warehouse.list}
                    edit={warehouse.edit}
                    create={warehouse.create}
                />

                <Resource
                    name="car"
                    options={{label: "Car"}}
                    list={car.list}
                    edit={car.edit}
                    create={car.create}
                />

                <Resource
                    name="application-config"
                    options={{label: "TayDo"}}
                    // list={applicationInformationMgmt.list}
                    show={applicationInformationMgmt.show}
                    edit={applicationInformationMgmt.edit}
                    // create={applicationInformationMgmt.create}
                />

                <Resource
                    name="hot-location"
                    options={{label: "HotLocation"}}
                    list={hotLocationManagement.list}
                    edit={hotLocationManagement.edit}
                    create={hotLocationManagement.create}
                    show={hotLocationManagement.show}
                />

                <Resource
                    name="office"
                    options={{label: "Office"}}
                    list={officeManagement.list}
                    edit={officeManagement.edit}
                    create={officeManagement.create}
                />

                <Resource
                    name="room-type"
                    options={{label: "RoomType"}}
                    list={roomTypeComponent.list}
                    edit={roomTypeComponent.edit}
                    create={roomTypeComponent.create}
                />

                <Resource
                    name="product"
                    options={{label: "Product"}}
                    list={productMgmt.list}
                    edit={productMgmt.edit}
                    create={productMgmt.create}
                />

                <Resource
                    name="category"
                    options={{label: "Category"}}
                    list={categoryMgmt.list}
                    edit={categoryMgmt.edit}
                    create={categoryMgmt.create}
                />

                {/* khai báo api get danh sách tỉnh thành*/}
                <Resource name="location-type"/>
            </Admin>
        </>
    );
};

export default App;
