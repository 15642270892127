import React from "react";
import {
    Edit, FunctionField, required, SimpleForm, TextInput, Toolbar,
    SaveButton
} from "react-admin";
import CancelButton from 'custom/CancelButton';

const ToolbarRoleEdit = (props) =>{
    return (
        <Toolbar {...props} >
            <SaveButton label="Submit" >Save</SaveButton>
            <CancelButton redirect="list"/>
        </Toolbar>
    )
}

export const RoleUpdate = (props) => {
    return (
        <Edit {...props} >
            <SimpleForm toolbar={<ToolbarRoleEdit/>}>
                <TextInput source="role_name" validate={[required()]} label='Tên Quyền' />
                <FunctionField label="Trạng thái" render={record => {
                    if (record.status === '1') return 'ACTIVE';
                    return 'INACTIVE';
                }} />
            </SimpleForm>
        </Edit>
    )
}