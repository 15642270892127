import {Box, useMediaQuery} from "@material-ui/core";
import FaceIcon from "@material-ui/icons/Face";
import GroupIcon from "@material-ui/icons/Group";
import SettingsIcon from "@material-ui/icons/Settings";
import RoomIcon from '@material-ui/icons/Room';
import BallotIcon from '@material-ui/icons/Ballot';
import ReceiptIcon from "@material-ui/icons/Receipt";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {MenuItemLink, useTranslate,} from "react-admin";
import {useSelector} from "react-redux";
import SubMenu from "./SubMenu";
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import {ADMINISTRATOR_ROLE} from 'providers/consts';
import HouseIcon from '@material-ui/icons/House';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import InfoIcon from '@material-ui/icons/Info';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import BusinessIcon from '@material-ui/icons/Business';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

const Menu = ({onMenuClick, dense, logout}) => {
    const [state, setState] = useState({
        menuUserManagement: false,
        menuRoomManagement: false,
        menuAmenity: false,
        menuProductMgmt: false,
    });
    const translate = useTranslate();
    const isXsmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    useSelector((state) => state.theme); // force rerender on theme change

    const handleToggle = (menu) => {
        setState((state) => ({...state, [menu]: !state[menu]}));
    };

    const roleName = localStorage.getItem("roleName");

    return (
        <Box mt={1}>
            {" "}
            {/*<DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />*/}
            <SubMenu
                handleToggle={() => handleToggle("menuProductMgmt")}
                isOpen={state.menuProductMgmt}
                sidebarIsOpen={open}
                name="Sản Phẩm"
                icon={<LocalOfferIcon/>}
            >
                <MenuItemLink
                    to={`/category`}
                    primaryText="Danh Mục"
                    onClick={onMenuClick}
                />
                <MenuItemLink
                    to={`/product`}
                    primaryText="Sản Phẩm"
                    onClick={onMenuClick}
                />
            </SubMenu>
            <MenuItemLink
                to={`/hot-location`}
                primaryText="Địa điểm nổi bật"
                leftIcon={<LocationCityIcon/>}
                onClick={onMenuClick}
            />
            <MenuItemLink
                to={`/location`}
                primaryText="Địa chỉ"
                leftIcon={<RoomIcon/>}
                onClick={onMenuClick}
            />
            <MenuItemLink
                to={`/warehouse`}
                primaryText="Kho cho thuê"
                leftIcon={<HomeWorkIcon/>}
                onClick={onMenuClick}
            />
            <MenuItemLink
                to={`/room`}
                primaryText="Phòng cho thuê"
                leftIcon={<HouseIcon/>}
                onClick={onMenuClick}
            />
            <MenuItemLink
                to={`/office`}
                primaryText="Văn Phòng cho thuê"
                leftIcon={<BusinessIcon/>}
                onClick={onMenuClick}
            />
            <MenuItemLink
                to={`/car`}
                primaryText="Xe cho thuê"
                leftIcon={<EmojiTransportationIcon/>}
                onClick={onMenuClick}
            />
            {/*<MenuItemLink*/}
            {/*    to={`/transaction`}*/}
            {/*    primaryText="Quản lý lịch đặt phòng"*/}
            {/*    leftIcon={<ReceiptIcon/>}*/}
            {/*    onClick={onMenuClick}*/}
            {/*/>*/}

            <MenuItemLink
                to={`/application-config/1/show`}
                primaryText="Thông tin hệ thống"
                leftIcon={<InfoIcon/>}
                onClick={onMenuClick}
            />

            <SubMenu
                handleToggle={() => handleToggle("menuRoomManagement")}
                isOpen={state.menuRoomManagement}
                sidebarIsOpen={open}
                name="Thông tin phòng"
                icon={<BallotIcon/>}
            >
                <MenuItemLink
                    to={`/amenity`}
                    primaryText="Tiện Nghi"
                    onClick={onMenuClick}
                />
                <MenuItemLink
                    to={`/feature`}
                    primaryText="Tiện Ích"
                    onClick={onMenuClick}
                />
                <MenuItemLink
                    to={`/room-type`}
                    primaryText="Loại Phòng"
                    onClick={onMenuClick}
                />
            </SubMenu>

            {/*<SubMenu*/}
            {/*    handleToggle={() => handleToggle("menuAmenity")}*/}
            {/*    isOpen={state.menuAmenity}*/}
            {/*    sidebarIsOpen={open}*/}
            {/*    name="Quản lý người dùng"*/}
            {/*    icon={<GroupIcon/>}*/}
            {/*>*/}
            {/*    {roleName === ADMINISTRATOR_ROLE && (*/}
            {/*        <>*/}
            {/*            <MenuItemLink*/}
            {/*                to={`/user`}*/}
            {/*                primaryText="Người dùng"*/}
            {/*                leftIcon={<FaceIcon/>}*/}
            {/*                onClick={onMenuClick}*/}
            {/*            />*/}
            {/*            <MenuItemLink*/}
            {/*                to={`/role`}*/}
            {/*                primaryText="Quyền"*/}
            {/*                leftIcon={<BubbleChartIcon/>}*/}
            {/*                onClick={onMenuClick}*/}
            {/*            />*/}
            {/*        </>*/}
            {/*    )}*/}
            {/*</SubMenu>*/}
            {isXsmall && (
                <MenuItemLink
                    to="/configuration"
                    primaryText={translate("pos.configuration")}
                    leftIcon={<SettingsIcon/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )}
            {isXsmall && logout}
        </Box>
    );
};

Menu.propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object,
};

export default Menu;
