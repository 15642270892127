import * as React from "react";
import { List, Datagrid, TextField, FunctionField, EditButton, useDelete  } from "react-admin";
import Chip from '@material-ui/core/Chip';

export const CategoryListComponent = (props) => {
    return (
        <List {...props} bulkActionButtons={false}title="Danh sách danh mục Sản Phẩm">
            <Datagrid>
                <EditButton/>
                <TextField source="categoryName" label = 'Danh mục sản phẩm'/>
                <FunctionField label="Trạng thái" render={record => {
                    return record.status ? <Chip label="ACTIVE" style={{ backgroundColor: '#6fbf73', color: '#fff' }} /> :
                        <Chip label="INACTIVE" style={{ backgroundColor: '#ff1744', color: '#fff' }} />;
                }} />
            </Datagrid>
        </List>
    )
};


