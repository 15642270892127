import React, {useState} from "react";
import {
    BooleanInput,
    Create,
    FormDataConsumer,
    ImageField,
    ImageInput,
    NumberInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'react-admin';
import './style.css';
import {SubmitToolbar} from './SubmitToolbar';
import {DialogLoading} from "custom/DialogLoading";
import {priceTypeCustomRequire, priceTypeRequire, roomPriceTypes} from "../Utils/PropertyUtils";

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '5%'},
    rightCol: {flex: 1, marginLeft: '5%'},
    singleCol: {marginTop: '1em', marginBottom: '1em'},
};

export const CarCreate = (props) => {
    const [isOpenLoading, setOpenLoading] = useState(false);
    return (
        <Create {...props} title='Thêm xe cho thuê'>
            <SimpleForm toolbar={<SubmitToolbar isCreateFrom={true} setOpenLoading={setOpenLoading}/>}>
                <DialogLoading openDialog={isOpenLoading} handleClose={setOpenLoading}/>
                <h3>Thông tin xe cho thuê</h3>
                <TextInput source="propertyTitle" label="Loại xe" validate={required()}
                           style={{width: '60rem'}}/>

                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <NumberInput label="Giá thuê" source="propertyPrice" min={1000}
                                     style={{width: '19.5rem'}}/>
                    </div>

                    <div style={styles.leftCol}>
                        <SelectInput source="priceType" label="Loại Giá" style={{width: '19.5rem'}}
                                     choices={roomPriceTypes}
                                     validate={[priceTypeRequire]}/>
                    </div>

                    <div style={styles.rightCol}>
                        <FormDataConsumer>
                            {({formData, ...rest}) => formData.priceType &&
                                formData.priceType === 'Tuỳ chỉnh' &&
                                <TextInput label="Loại giá thuê" source="priceTypeCustom" style={{width: '19rem'}}
                                           validate={[priceTypeCustomRequire]}/>
                            }
                        </FormDataConsumer>
                    </div>
                </div>

                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <NumberInput label="Số chổ ngồi" source="seatingCapacity" min={0} style={{width: '19rem'}}
                                     validate={required()}/>
                    </div>

                    <div style={styles.rightCol}>
                        <SelectInput source="status" label="Trạng thái" style={{width: '19.5rem'}}
                                     validate={required()} initialValue={true} choices={[
                            {id: true, name: 'Cho thuê'},
                            {id: false, name: 'Không cho thuê'}
                        ]}/>
                    </div>
                </div>

                <div style={{width: '60rem'}}>
                    <ImageInput source="images" label="Hình ảnh xe" style={{width: '60rem'}}
                                accept="image/*" placeholder={<p>Kéo thả ảnh vào đây...</p>}
                                validate={required()} multiple={true}>
                    <ImageField source="src" title="title"/>
                    </ImageInput>
                </div>
            </SimpleForm>
        </Create>
    )
}