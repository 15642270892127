import {ListView} from "./ListView";
import {UpdateForm} from './UpdateForm';
import {CreateForm} from './CreateForm';
import {ShowInformation} from "./ShowInformation";

export default {
    list: ListView,
    edit: UpdateForm,
    create: CreateForm,
    show: ShowInformation
};
