import * as React from "react";
import {
    BooleanInput,
    DateInput,
    Edit,
    email,
    regex,
    required,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar
} from "react-admin";
import CancelButton from 'custom/CancelButton';

const validateRequired = required("This field is required.")
const validatePhone = regex(/^[0]{1,4}[)]{0,1}[-\s\./0-9]*$/, "Must be a valid Phone Number");

const ToolbarUserEdit = (props) =>{
    return (
        <Toolbar {...props} >
            <SaveButton label="Submit" >Save</SaveButton>
            <CancelButton redirect="list"/>
        </Toolbar>
    )
}
export const UserEdit = (props) => (
    <Edit {...props} title='Chỉnh sửa người dùng'>
        <SimpleForm  toolbar={<ToolbarUserEdit/>}>
            <TextInput source="id" disabled label='ID'/>
            <TextInput source="name" validate={[validateRequired]} disabled label='Tên Người dùng'/>
            <TextInput source="email" validate={[validateRequired, email()]} disabled label='Email'/>
            <TextInput source="phone_number" validate={[validatePhone]} disabled label='Số điện thoại'/>
            <TextInput source="address" disabled label ='Địa chỉ'/>
            <DateInput source="dob" label="Ngày sinh" disabled/>
            <BooleanInput source="status" label="Trạng thái"/>
        </SimpleForm>
    </Edit>
);
