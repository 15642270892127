import React from "react";
import {
    ArrayInput,
    BooleanInput,
    Edit, FormDataConsumer,
    FormTab, ImageField, ImageInput, NumberInput,
    required,
    SaveButton,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
    Toolbar,
} from "react-admin";
import CancelButton from 'custom/CancelButton';
import {RichTextInput} from 'ra-richtext-tiptap';

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '5%'},
    rightCol: {flex: 1, marginLeft: '5%'},
    singleCol: {marginTop: '1em', marginBottom: '1em'},
};


const ToolbarEdit = (props) => {
    return (<Toolbar {...props} >
        <SaveButton label="Lưu"/>
        <CancelButton redirect="list"/>
    </Toolbar>)
}

export const UpdateForm = (props) => {

    return (<Edit {...props} title={"Quản lý thông tin hệ thống TaydoSpace"}>
        <TabbedForm toolbar={<ToolbarEdit/>} redirect="list">
            <FormTab label="Thông tin cơ bản">
                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <TextInput source="title" validate={[required()]} label='Tiêu đề'
                                   style={{width: '29rem'}}/>
                    </div>

                    <div style={styles.rightCol}>
                        <TextInput source="hotline" validate={[required()]} label='Hotline'
                                   style={{width: '29.5rem'}}/>
                    </div>
                </div>

                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <TextInput source="email" validate={[required()]} label='Email' style={{width: '29rem'}}/>
                    </div>

                    <div style={styles.rightCol}>
                        <TextInput source="website" validate={[required()]} label='Website'
                                   style={{width: '29.5rem'}}/>
                    </div>
                </div>

                <div style={styles.flex}>
                    <div style={styles.singleCol}>
                        <RichTextInput source="description" style={{width: '29.5rem'}}
                                       validate={[required()]} label='Giới thiệu'/>
                    </div>
                </div>

            </FormTab>
            <FormTab label="Chi nhánh" path="chi-nhanh">
                <ArrayInput source="taydoBranches" label={""}>
                    <SimpleFormIterator disableReordering>
                        <TextInput source="address" label={"Địa chỉ"} style={{width: '39rem'}}/>
                        <BooleanInput label="Trụ sở" source="headOffice"/>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="CMS" path="cms">
                <h4>Header configuration</h4>
                <BooleanInput label="Hiển thị tiêu đề 1" source="showHeaderOne"/>
                <FormDataConsumer>
                    {({formData, ...rest}) => formData.showHeaderOne &&
                        <>
                            <TextInput source="titleHeaderOne" validate={[required()]} label='Tiêu đề 1'
                                       style={{width: '29rem'}}/>
                            <ImageInput source="imageHeaderOne" label="Hinh ảnh tiểu đề 1"
                                        accept="image/*" placeholder={<p>Kéo thả ảnh vào đây...</p>}
                                        validate={required()}>
                                <ImageField source="imageHeaderOne" title="title"/>
                            </ImageInput>
                        </>
                    }
                </FormDataConsumer>

                <BooleanInput label="Hiển thị tiêu đề 2" source="showHeaderTwo"/>
                <FormDataConsumer>
                    {({formData, ...rest}) => formData.showHeaderTwo &&
                        <>
                            <TextInput source="titleHeaderTwo" validate={[required()]} label='Tiêu đề 2'
                                       style={{width: '29rem'}}/>
                            <ImageInput source="imageHeaderTwo" label="Hinh ảnh tiểu đề 2"
                                        accept="image/*" placeholder={<p>Kéo thả ảnh vào đây...</p>}
                                        validate={required()}>
                                <ImageField source="imageHeaderTwo" title="title"/>
                            </ImageInput>
                        </>
                    }
                </FormDataConsumer>

            </FormTab>
        </TabbedForm>

    </Edit>)
}