import {DialogLoading} from "custom/DialogLoading";
import {useState} from "react";
import {Edit, ImageField, ImageInput, required, SelectInput, SimpleForm, TextInput} from 'react-admin';
import {SubmitToolbar} from './SubmitToolbar';

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '5%'},
    rightCol: {flex: 1, marginLeft: '5%'},
    singleCol: {marginTop: '1em', marginBottom: '1em'},
};

export const EditComponent = (props) => {
    const [isOpenLoading, setOpenLoading] = useState(false);
    return (
        <Edit {...props} title='Chỉnh sửa phòng'>
            <SimpleForm toolbar={<SubmitToolbar isCreateFrom={false} setOpenLoading={setOpenLoading}/>}>

                <div style={styles.flex}>
                    <div style={styles.singleCol}>
                        <DialogLoading openDialog={isOpenLoading} handleClose={setOpenLoading}/>
                    </div>
                </div>

                <div style={styles.flex}>
                    <div style={styles.singleCol}>
                        <TextInput source="name" label="Tên địa điểm nổi bật" validate={required()}
                                   style={{width: '60rem'}}/>
                    </div>
                </div>

                <div style={styles.flex}>
                    <div style={styles.singleCol}>
                        <SelectInput source="status" label="Trạng thái" style={{width: '29rem'}}
                                     validate={required()} initialValue={true} choices={[
                            {id: true, name: 'Active'},
                            {id: false, name: 'InActive'}
                        ]}/>
                    </div>
                </div>

                <div style={{width: '60rem'}}>
                    <ImageInput source="hotLocationImg" label="Hình ảnh" style={{width: '60rem'}}
                                accept="image/*" placeholder={<p>Kéo thả ảnh vào đây...</p>}
                                multiple={false}>
                        <ImageField source="image" title="title"/>
                    </ImageInput>
                </div>
            </SimpleForm>
        </Edit>
    )
}