import { Card, CardMedia } from '@material-ui/core';
import { useRecordContext } from 'react-admin';

const Poster = () => {
    const record = useRecordContext();
    if (!record) return null;
    return (
        <Card sx={{ display: 'inline-block' }} style={{width: '200px', height: '200px'}}>
            <img src={record.image} alt="" width={200} height={200} />
            {/* <CardMedia
                component="img"
                image={record.image}
                alt=""
                sx={{ maxWidth: '100px', maxHeight: '100px' }}
            /> */}
        </Card>
    );
};

export default Poster;