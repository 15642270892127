import React from "react";
import {Datagrid, EditButton, FunctionField, List, TextField,} from "react-admin";
import Chip from '@material-ui/core/Chip';
import DateFieldCustom from "custom/DateFieldCustom";

export const RoomTypeList = (props) => {
    return (
        <List {...props} bulkActionButtons={false} title={"Danh sách loại phòng"}>
            <Datagrid>
                <EditButton label={""}/>
                <TextField source="roomTypeName" label={"Loại phòng"}/>
                <FunctionField source="status" label="Status"
                               render={record => {
                                   return record.status ?
                                       <Chip label="ACTIVE" style={{backgroundColor: '#6fbf73', color: '#fff'}}/> :
                                       <Chip label="INACTIVE" style={{backgroundColor: '#ff1744', color: '#fff'}}/>;
                               }}/>

                <DateFieldCustom source="createdDateTime" label={"Ngày Tạo"} format="DD/MM/YYYY"/>
            </Datagrid>
        </List>
    )
}