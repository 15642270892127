import {AUTH_URL} from './configProvider';

export default {
    login: ({username, password}) => {
        const request = new Request(`${AUTH_URL}/login`, {
            method: 'POST',
            body: JSON.stringify({username: username, password}),
            headers: new Headers({'Content-Type': 'application/json'})
        });

        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({accessToken, userName, roleName}) => {
                localStorage.setItem('authToken', accessToken);
                localStorage.setItem('username', userName);
                localStorage.setItem('roleName', roleName);
            });
    },
    logout: () => {
        localStorage.removeItem('username');
        localStorage.removeItem('authToken');
        localStorage.removeItem('roleName');
        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => localStorage.getItem('authToken') ? Promise.resolve() : Promise.reject(),
    getPermissions: () => Promise.reject('Unknown method'),
};
