import Chip from '@material-ui/core/Chip';
import { Datagrid, FunctionField, List, ReferenceField, TextField, useRedirect } from "react-admin";

export const ListComponent = (props) => {
    const redirect = useRedirect();

    return (
        <List {...props} bulkActionButtons={false} title='Danh sách địa điểm nổi bật'>
            <Datagrid rowClick="show">
                {/* <EditButton label={"Sửa"}/>
                <ShowButton basePath="/hot-location" label="Hiển thị" /> */}
                {/*<TextField source="id" label='ID' />*/}
                <ReferenceField source="id" reference="hot-location" link="show" label='Tên Địa điểm' >
                    <TextField source="name" />
                </ReferenceField>
                {/* <TextField source="name" label='Tên Địa điểm' /> */}
                <TextField source="totalRoom" label='Số phòng' />
                <FunctionField label="Trạng thái" render={record => {
                    return record.status ?
                        <Chip label="Active" style={{ backgroundColor: '#6fbf73', color: '#fff' }} /> :
                        <Chip label="InActive" style={{ backgroundColor: '#ff1744', color: '#fff' }} />;
                }} />
            </Datagrid>
        </List>
    )
};
