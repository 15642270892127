import * as React from "react";
import {
    CreateButton,
    Datagrid,
    EditButton,
    ExportButton,
    Filter,
    FunctionField,
    List,
    ReferenceInput,
    SearchInput,
    TextField,
    TextInput,
    TopToolbar
} from "react-admin";
import Chip from '@material-ui/core/Chip';
import {formatMoney} from "providers/utils";
import PropertyTypeConst from "providers/PropertyTypeConst";

const RoomFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn/>
        <ReferenceInput label="User Name" source="user_id" reference="user" allowEmpty>
            <TextInput source="name"/>
        </ReferenceInput>
    </Filter>
);

const ListActions = (props) => (
    <TopToolbar>
        <CreateButton label={"Tạo phòng"}/>
        <ExportButton label={"Xuất File"}/>
    </TopToolbar>
);

export const RoomList = (props) => {
    return (
        <List {...props} bulkActionButtons={false} title='Danh sách Phòng'
              filter={{location: {locationType: PropertyTypeConst.ROOM_FOR_RENT.key}}}
              actions={<ListActions/>}>
            <Datagrid>
                <EditButton label={""}/>
                <TextField source="propertyTitle" label='Tên Phòng'/>
                <TextField source="location.fullLocationName" label='Địa chỉ'/>
                <FunctionField label="Giá" render={record => {
                    if (!record.propertyPrice)
                        return 'Liên Hệ';

                    let priceType = record.priceType;
                    if (record.priceTypeCustom) {
                        priceType = record.priceTypeCustom;
                    }
                    return formatMoney(record.propertyPrice) + '/' + priceType;
                }}/>

                <TextField source="bedroom" label='Phòng ngủ'/>
                <TextField source="bathroom" label='Phòng tắm'/>
                <TextField source="propertySquare" label='Diện tích'/>
                <FunctionField label="Trạng thái" render={record => {
                    return record.status ?
                        <Chip label="Cho thuê" style={{backgroundColor: '#6fbf73', color: '#fff'}}/> :
                        <Chip label="Không cho thuê" style={{backgroundColor: '#ff1744', color: '#fff'}}/>;
                }}/>
            </Datagrid>
        </List>
    )
};

