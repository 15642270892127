import * as React from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow,} from '@material-ui/core';
import {Link, useRecordContext} from 'react-admin';
import {TableCellRight} from 'custom/TableCellRight';
import PropertyTypeConst from "providers/PropertyTypeConst";

const Basket = () => {
    const record = useRecordContext();

    const getLocationTypeValue = (key) => {
        switch (key) {
            case PropertyTypeConst.WAREHOUSE_FOR_RENT.key:
                return PropertyTypeConst.WAREHOUSE_FOR_RENT.value;
            case PropertyTypeConst.ROOM_FOR_RENT.key:
                return PropertyTypeConst.ROOM_FOR_RENT.value;
            case PropertyTypeConst.CAR_FOR_RENT.key:
                return PropertyTypeConst.CAR_FOR_RENT.value;
            case PropertyTypeConst.REAL_ESTATE.key:
                return PropertyTypeConst.REAL_ESTATE.value;
            case PropertyTypeConst.OFFICE_FOR_RENT.key:
                return PropertyTypeConst.OFFICE_FOR_RENT.value;
            default:
                // code block
                return '';
        }
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Địa Chỉ </TableCell>
                    <TableCell>Loại Địa Chỉ </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {record.locationList ? record.locationList.map((item) => (
                    <TableRow key={item.product_id}>
                        <TableCell>
                            <Link to={`/location/${item.id}`}>
                                {item.fullLocationName}
                            </Link>
                        </TableCell>

                        <TableCell>
                            {
                                getLocationTypeValue(item.locationType)
                            }
                        </TableCell>
                    </TableRow>
                )) : <div></div>}
            </TableBody>
        </Table>
    );
};

export default Basket;