import React from "react";
import {Datagrid, EditButton, FunctionField, List, TextField} from "react-admin";
import DateFieldCustom from 'custom/DateFieldCustom';
import Chip from '@material-ui/core/Chip';
import PropertyTypeConst from 'providers/PropertyTypeConst'

export const ListLocation = (props) => {
    return (
        <List {...props} bulkActionButtons={false} title="Quản lý địa chỉ">
            <Datagrid>
                <EditButton label={''}/>
                <TextField source="locationName" label={"Tên địa điểm"}/>
                <TextField source="locationProvince" label={"Tỉnh/Thành phố"}/>
                <TextField source="locationDistrict" label={"Quận/Huyện"}/>
                <TextField source="locationWard" label={"Phường/Xã"}/>
                <TextField source="locationAddress" label={"Địa chỉ"}/>

                <FunctionField source="locationType" label='Loại địa chỉ'
                               render={record => {
                                   if (record.locationType === PropertyTypeConst.WAREHOUSE_FOR_RENT.key) {
                                       return PropertyTypeConst.WAREHOUSE_FOR_RENT.value;
                                   } else if (record.locationType === PropertyTypeConst.ROOM_FOR_RENT.key) {
                                       return PropertyTypeConst.ROOM_FOR_RENT.value;
                                   } else if (record.locationType === PropertyTypeConst.REAL_ESTATE.key) {
                                       return PropertyTypeConst.REAL_ESTATE.value;
                                   } else if (record.locationType === PropertyTypeConst.OFFICE_FOR_RENT.key) {
                                       return PropertyTypeConst.OFFICE_FOR_RENT.value;
                                   }
                                   return '';
                               }}/>

                <FunctionField source="status" label='Trạng Thái'
                               render={record => {
                                   return record.status ?
                                       <Chip label="ACTIVE" style={{backgroundColor: '#6fbf73', color: '#fff'}}/> :
                                       <Chip label="INACTIVE" style={{backgroundColor: '#ff1744', color: '#fff'}}/>;
                               }}/>
            </Datagrid>
        </List>
    )
}