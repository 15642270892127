import * as React from 'react';
import {useEffect, useState} from 'react';
import {Card, CardContent} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined';
import BarChartIcon from '@material-ui/icons/BarChart';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {FilterList, FilterListItem, FilterLiveSearch, useDataProvider} from 'react-admin';
import {string} from "prop-types";

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            width: '15em',
            marginRight: '1em',
            overflow: 'initial',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

export const Aside = (props) => {
    const dataProvider = useDataProvider();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        dataProvider.getList('category', {
            pagination: {page: 1, perPage: 10},
            sort: {field: 'categoryName', order: 'ASC'},
            filter: {status: 1}
        })
            .then(({data}) => {
                setData(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, []);


    // const { data, ids, loading, error } = useGetList(
    //     'category',
    //     {page: 1, perPage: 10},
    //     {field: 'categoryName', order: 'ASC'},
    //     {status: 1}
    // );
    // if (!loading) {
    //     console.log("data",data)
    //     console.log("props",props)
    // }
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardContent>
                <FilterLiveSearch label={"Tìm kiếm"} source={'productName'}/>

                <FilterList
                    label="Sản Phẩm"
                    icon={<AttachMoneyIcon/>}
                >
                    {/*<FilterListItem*/}
                    {/*    label="Best Sellers"*/}
                    {/*    value={{*/}
                    {/*        sales_lte: undefined,*/}
                    {/*        sales_gt: 25,*/}
                    {/*        sales: undefined,*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<FilterListItem*/}
                    {/*    label="Sản phẩm bán trung bình"*/}
                    {/*    value={{*/}
                    {/*        sales_lte: 25,*/}
                    {/*        sales_gt: 10,*/}
                    {/*        sales: undefined,*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<FilterListItem*/}
                    {/*    label="Sản phẩm ít lượt mua"*/}
                    {/*    value={{*/}
                    {/*        sales_lte: 10,*/}
                    {/*        sales_gt: 0,*/}
                    {/*        sales: undefined,*/}
                    {/*    }}*/}
                    {/*/>*/}
                    <FilterListItem
                        label="Sản phẩm đang bán"
                        value={{
                            status: true
                        }}
                    />
                    <FilterListItem
                        label="Sản phẩm không bán"
                        value={{
                            status: false
                        }}
                    />
                </FilterList>

                {/*<FilterList*/}
                {/*    label="Số lượng"*/}
                {/*    icon={<BarChartIcon/>}*/}
                {/*>*/}
                {/*    <FilterListItem*/}
                {/*        label="Hết"*/}
                {/*        value={{*/}
                {/*            status: false,*/}
                {/*            stockQuantity: 0,*/}
                {/*        }}*/}
                {/*    />*/}
                {/*    <FilterListItem*/}
                {/*        label="Còn ít"*/}
                {/*        value={{*/}
                {/*            stockQuantity: 10*/}
                {/*        }}*/}
                {/*    />*/}
                {/*    <FilterListItem*/}
                {/*        label="Còn sản phẩm"*/}
                {/*        value={{*/}
                {/*            status: true*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</FilterList>*/}


                <FilterList
                    label="Danh mục Sản Phẩm"
                    icon={<LocalOfferIcon/>}
                >
                    {!loading && data &&
                        data.map((datum) => (
                            <FilterListItem
                                label={datum.categoryName}
                                key={datum.id}
                                value={{category: {id: datum.id}}}
                            />
                        ))}
                </FilterList>
            </CardContent>
        </Card>
    );
};
