import React from "react";
import {Datagrid, EditButton, FunctionField, List, TextField} from "react-admin";
import DateFieldCustom from 'custom/DateFieldCustom';
import Chip from '@material-ui/core/Chip';

export const ListRole = (props) => {
    return (
        <List {...props} bulkActionButtons={false} title="Quản lý quyền truy cập">
            <Datagrid>
                <TextField source="roleName" label={"Quyền truy cập"}/>
                <FunctionField source="status" label='Trạng Thái'
                               render={record => {
                                   return record.status ?
                                       <Chip label="ACTIVE" style={{backgroundColor: '#6fbf73', color: '#fff'}}/> :
                                       <Chip label="INACTIVE" style={{backgroundColor: '#ff1744', color: '#fff'}}/>;
                               }}/>
                <DateFieldCustom source="createdDateTime" label={"Ngày Tạo"}/>
                <DateFieldCustom source="updatedDateTime" label={"Ngày chỉnh sửa"}/>
            </Datagrid>
        </List>
    )
}