import React, {useState} from "react";
import {
    Edit,
    FormDataConsumer,
    ImageField,
    ImageInput,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from 'react-admin';
import {SubmitToolbar} from './SubmitToolbar';
import PropertyTypeConst from "providers/PropertyTypeConst";
import {DialogLoading} from "custom/DialogLoading";
import {priceTypeCustomRequire, priceTypeRequire, roomPriceTypes} from "../Utils/PropertyUtils";

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '5%'},
    rightCol: {flex: 1, marginLeft: '5%'},
    singleCol: {marginTop: '1em', marginBottom: '1em'},
};

export const WarehouseEdit = (props) => {
    const [isOpenLoading, setOpenLoading] = useState(false);
    return (
        <Edit {...props} title='Chỉnh sửa phòng'>
            <SimpleForm toolbar={<SubmitToolbar isCreateFrom={false} setOpenLoading={setOpenLoading}/>}>
                <DialogLoading openDialog={isOpenLoading} handleClose={setOpenLoading}/>
                <TextInput source="propertyTitle" label="Tên kho" validate={required()}
                           style={{width: '60rem'}}/>

                <ReferenceInput source="location.id" label="Địa chỉ" reference="location"
                                filter={{locationType: PropertyTypeConst.WAREHOUSE_FOR_RENT.key}} style={{width: '60rem'}}>
                    <SelectInput optionText="fullLocationName" optionValue="id" style={{width: '60rem'}}
                                 validate={required()}/>
                </ReferenceInput>

                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <NumberInput label="Giá thuê" source="propertyPrice" min={1000}
                                     style={{width: '19.5rem'}}/>
                    </div>

                    <div style={styles.leftCol}>
                        <SelectInput source="priceType" label="Loại Giá" style={{width: '19.5rem'}}
                                     choices={roomPriceTypes}
                                     validate={[priceTypeRequire]}/>
                    </div>

                    <div style={styles.rightCol}>
                        <FormDataConsumer>
                            {({formData, ...rest}) => formData.priceType &&
                                formData.priceType === 'Tuỳ chỉnh' &&
                                <TextInput label="Loại giá thuê" source="priceTypeCustom" style={{width: '19rem'}}
                                           validate={[priceTypeCustomRequire]}/>
                            }
                        </FormDataConsumer>
                    </div>
                </div>


                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <NumberInput label="Thời gian thuê tối thiểu (tháng)" source="minRentalPeriod" min={1}
                                     style={{width: '29.5rem'}}
                                     initialValue={6} validate={required()}/>
                    </div>

                    <div style={styles.rightCol}>
                        <NumberInput label="Diện tích kho" source="propertySquare" min={0} style={{width: '29rem'}}
                                     validate={required()}/>
                    </div>
                </div>

                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <SelectInput source="status" label="Trạng thái kho" style={{width: '29rem'}}
                                     validate={required()} choices={[
                            {id: true, name: 'Cho thuê'},
                            {id: false, name: 'Không cho thuê'}
                        ]}/>
                    </div>
                </div>

                <div style={{width: '60rem'}}>
                    <ImageInput source="propertyImages" label="Cập nhật hình ảnh kho" style={{width: '60rem'}}
                                accept="image/*" placeholder={<p>Kéo thả ảnh của bạn vào đây...</p>}
                                validate={required()} multiple={true}>
                    <ImageField source="propertyImage" title="title"/>
                    </ImageInput>
                </div>
            </SimpleForm>
        </Edit>
    )
}