let WAREHOUSE_FOR_RENT = {
    key: "WAREHOUSE_FOR_RENT",
    value: "Kho cho thuê"
};
let ROOM_FOR_RENT = {
    key: "ROOM_FOR_RENT",
    value: "Phòng cho thuê"
};

let CAR_FOR_RENT = {
    key: "CAR_FOR_RENT",
    value: "Xe cho thuê"
};


let REAL_ESTATE = {
    key: "REAL_ESTATE",
    value: "Thông tin BĐS"
};

let OFFICE_FOR_RENT = {
    key: "OFFICE_FOR_RENT",
    value: "Văn Phòng cho thuê"
};


export default {
    WAREHOUSE_FOR_RENT,
    ROOM_FOR_RENT,
    CAR_FOR_RENT,
    REAL_ESTATE,
    OFFICE_FOR_RENT
}