import React from 'react';
import {Edit, TextInput, SimpleForm, required, FunctionField, BooleanInput, Toolbar, SaveButton} from 'react-admin';
import CancelButton from "../../../custom/CancelButton";

const ToolbarEdit = (props) => {
    return (
        <Toolbar {...props} >
            <SaveButton label="Lưu"/>
            <CancelButton redirect="list"/>
        </Toolbar>
    )
}

export const AmenityEdit = props => (
    <Edit title="Cập nhật Tiện Nghi" {...props} >
        <SimpleForm toolbar={<ToolbarEdit/>}>
            <TextInput source="amenityName" validate={required()}/>
            <BooleanInput source='status' label="Status"/>
        </SimpleForm>
    </Edit>
);