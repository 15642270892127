import React from "react";
import {BooleanInput, Create, required, SimpleForm, TextInput} from 'react-admin';

export const RoleCreate = (props) => {
    return (
        <Create {...props} title ='Tạo thêm Quyền mới'>
            <SimpleForm>
                <TextInput source="roleName" validate={[required()]} label='Tên quyền'/>
                <BooleanInput source="status" label="Role Status" initialValue={true} label='Trạng thái'/>
            </SimpleForm>
        </Create>
    )
}