import React from 'react';
import {
    Edit, TextInput, SimpleForm, required, BooleanInput, Toolbar,
    SaveButton
} from 'react-admin';
import CancelButton from 'custom/CancelButton';

const ToolbarRoomTypeEdit = (props) => {
    return (
        <Toolbar {...props} >
            <SaveButton label="Submit" >Save</SaveButton>
            <CancelButton redirect="list" />
        </Toolbar>
    )
}
export const CategoryEditComponent = props => (
    <Edit title="Update Danh Mục Sản Phẩm" {...props}>
        <SimpleForm toolbar={<ToolbarRoomTypeEdit/>}>
            <TextInput source="categoryName" label='Danh mục Sản Phẩm' validate={required()} />
            <BooleanInput source='status' label="Trạng thái" />
        </SimpleForm>
    </Edit>
);