import {ListComponent} from "./ListComponent";
import {CreateComponent} from './CreateComponent';
import {EditComponent} from './EditComponent';
import {ShowComponent} from './ShowComponent';

export default {
    list: ListComponent,
    create: CreateComponent,
    edit: EditComponent,
    show: ShowComponent
};
