import * as React from 'react';
import {useState} from 'react';
import {
    BooleanInput,
    Create,
    FormDataConsumer,
    ImageField,
    ImageInput,
    NumberInput, ReferenceArrayInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import {ProductSubmitToolbar} from "./ProductSubmitToolbar";
import {DialogLoading} from "custom/DialogLoading";
import {RichTextInput} from "ra-richtext-tiptap";
import PropertyTypeConst from "../../../providers/PropertyTypeConst";

export const styles = {
    price: {width: '7em'},
    width: {width: '7em'},
    height: {width: '7em'},
    stock: {width: '7em'},
    widthFormGroup: {display: 'inline-block'},
    heightFormGroup: {display: 'inline-block', marginLeft: 32},
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '5%'},
    rightCol: {flex: 1, marginLeft: '5%'},
    singleCol: {marginTop: '1em', marginBottom: '1em'},
};

const useStyles = makeStyles(styles);

const ProductCreate = (props) => {
    const classes = useStyles();
    const [isOpenLoading, setOpenLoading] = useState(false);
    return (
        <Create {...props} title={"Tạo Sản Phẩm"}>
            <SimpleForm toolbar={<ProductSubmitToolbar isCreateFrom={true} setOpenLoading={setOpenLoading}/>}>
                <DialogLoading openDialog={isOpenLoading} handleClose={setOpenLoading}/>
                <h3>Thông tin Sản Phẩm</h3>

                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <TextInput source="productName" label="Tên Sản Phẩm" validate={required()}
                                   style={{width: '19rem'}}/>
                    </div>
                    <div style={styles.rightCol}>
                        <ReferenceArrayInput source="categoryId" label="Danh Mục" reference="category"
                                             filter={{status: true}}
                                             style={{width: '19rem'}}>
                            <SelectInput optionText="categoryName" optionValue={"id"} style={{width: '19rem'}}
                                         validate={required()}/>
                        </ReferenceArrayInput>
                    </div>
                </div>


                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <BooleanInput source="isNew" label="Sản Phẩm mới" style={{width: '19rem'}}/>
                    </div>
                    <div style={styles.rightCol}>
                        <BooleanInput source="isHot" label="Sản Phẩm Hot" style={{width: '19rem'}}/>
                    </div>
                </div>
                <div style={styles.flex}>
                    <div style={styles.singleCol}>
                        <RichTextInput source="description" style={{width: '29.5rem'}}
                                       validate={[required()]} label='Giới thiệu Sản Phẩm'/>
                    </div>
                </div>

                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <BooleanInput source="isShowPrice" label="Hiển thị giá" style={{width: '19rem'}}/>
                    </div>
                    <div style={styles.rightCol}>
                        <FormDataConsumer>
                            {({formData, ...rest}) =>
                                formData.isShowPrice &&
                                <NumberInput label="Giá bán" source="price" min={1000}
                                             style={{width: '19.5rem'}}
                                             validate={formData.isShowPrice ? required() : {}}/>
                            }
                        </FormDataConsumer>
                    </div>
                </div>


                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <NumberInput label="Số lượng trong kho" source="stockQuantity" min={0}
                                     style={{width: '19rem'}}
                                     validate={required()}/>
                    </div>
                    <div style={styles.rightCol}>
                        <SelectInput source="status" label="Trạng thái" style={{width: '19rem'}}
                                     validate={required()} initialValue={true} choices={[
                            {id: true, name: 'Bán'},
                            {id: false, name: 'Không bán'}
                        ]}/>
                    </div>
                </div>

                <div style={{width: '42rem'}}>
                    <ImageInput source="imageRequest" label="Hình ảnh" style={{width: '42rem'}}
                                accept="image/*" placeholder={<p>Kéo thả ảnh của bạn vào đây...</p>}
                                 multiple={true} validate={required()}>
                        <ImageField source="src" title="title"/>
                    </ImageInput>
                </div>
            </SimpleForm>
        </Create>
    );
};

export default ProductCreate;