import React, { useCallback } from 'react';
import { Button, useRedirect} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';

const CancelButton = props => {
    const redirectTo = useRedirect();
    const { basePath, redirect } = props;
    const handleClick = useCallback(() => {
        redirectTo(basePath);
    }, [redirect]);

    return <Button {...props} onClick={handleClick} label="Quay về" ><IconCancel/></Button>;
};

export default CancelButton;