import {ADMIN_URL} from './configProvider';
import moment from 'moment';
import {unparse as convertToCSV} from 'papaparse/papaparse.min';
import {downloadCSV} from 'react-admin';
import {urlB64ToUint8Array} from "../Utility";


export const getHeader = () => {
    const token = localStorage.getItem('authToken');
    return {
        headers: new Headers({
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
            'content-type': 'application/json'
        })
    };
};
export const getBackendUrl = (resource) => {
    return ADMIN_URL;
};

export const getApiByResource = (resource) => {
    switch (resource) {
        default:
            return resource;
    }
};

export const formatDatetime = (rawDatetime, format = 'DD-MMM-YYYY HH:mm:ss') => {
    return moment(rawDatetime).format(format).toString();
};

export const getDatetime = (format = 'YYYYMMDD_HHmm') => {
    return moment().format(format);
};

export const getExportFile = (data, listExportColumns, fileName, format) => {
    const csv = convertToCSV({
        data: data,
        fields: listExportColumns
    });
    downloadCSV(csv, `${fileName}_${getDatetime(format)}`);
};

export function formatMoney(amount, decimalCount = 0, decimal = ".", thousands = ",") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
};

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
export const addUploadFeature = (fileInput) => {
    // only freshly dropped pictures are instance of File
    // const formerPictures = params.data.files.filter(p => !(p.rawFile instanceof File));
    //const newPictures = params.data.files.filter(p => p.rawFile instanceof File);
    if (fileInput) {
        return Promise.resolve(convertFileToBase64(fileInput)).then((fileData) => {
            return {
                src: fileData,
                title: `${fileInput.title}`
            }
        });
    }
};


/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    // remove data:*/*;base64, from the result
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

export const isFetchSuccess = (httpStatus = 200) => {
    return httpStatus >= 200 && httpStatus < 300
}

export const subscribeUser = () => {
    if (navigator && navigator.serviceWorker) {
        navigator.serviceWorker.ready
            .then((registration) => {
                const subscribeOptions = {
                    userVisibleOnly: true,
                    applicationServerKey: urlB64ToUint8Array(
                        'BKJBQxpM8-Do05TPun20QHEebZxmJbdrViflKa2IptmUfrgPxATcVTwZg2MPcvEgUHZIViOyVISSaO37ZkqIhdk'
                    )
                };

                let subscribe = registration.pushManager.subscribe(subscribeOptions);
                return subscribe;
            })
            .then((pushSubscription) => {
                storePushSubscription(pushSubscription);
            });
    }
}

const storePushSubscription = async (pushSubscription) => {
    const backendUrl = getBackendUrl();
    const options = {
        ...getHeader(),
        method: 'POST',
        body: JSON.stringify(pushSubscription),
    };
    await fetch(`${backendUrl}/subscription`, options)
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            console.log(res)
        })
        .catch((err) => {
            console.log(err)
        });
}

export const getAddressVietnam = (addressVietnamFile) => {
    return JSON.parse(JSON.stringify(addressVietnamFile));
}

export const getDistrictsByProvinceName = (addressVietnamObj, provinceName) => {
    if (!addressVietnamObj) {
        return [];
    }
    let provinceObj = addressVietnamObj.filter(address => address.name === provinceName)[0];
    if (!provinceObj || !provinceObj.districts) {
        return [];
    }

    return provinceObj.districts;
}

export const getWardByDistrictName = (districtByProvinceObj, districtName) => {
    if (!districtByProvinceObj) {
        return [];
    }
    let districtObj = districtByProvinceObj.filter(district => district.name === districtName)[0];
    if (!districtObj || Object.keys(districtObj) === 0 || !districtObj.wards) {
        return [];
    }
    return districtObj.wards;
}

export function getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
}

export function slugify(text) {
    return text.toString().toLowerCase()
        .replace(/\s+/g, '-')       // Replace spaces with -
        .replace(/[^\w\-]+/g, '')   // Remove all non-word chars
        .replace(/\-\-+/g, '-')     // Replace multiple - with single -
        .replace(/^-+/, '')         // Trim - from start of text
        .replace(/-+$/, '');        // Trim - from end of text
}

export function getLast4DigitsOfTimestamp() {
    var timestamp = Date.now();
    var last4Digits = String(timestamp).slice(-4);
    return last4Digits;
}

export function getFileExtension(filename) {
    return filename.split('.').pop();
}
