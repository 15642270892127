import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { formatDatetime } from "providers/utils";

const styles = {
  text: {
    fontSize: "0.875rem",
  },
};

const DateFieldCustom = withStyles(
  styles
)(({ classes, source, record, format }) => (
  <span className={classes.text}>{formatDatetime(record[source], format)}</span>
));

DateFieldCustom.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  format: PropTypes.string,
};

export default DateFieldCustom;
