import React from 'react';
import {Create, TextInput, SimpleForm, required, BooleanInput} from 'react-admin';

export const CategoryCreateComponent= props => (
    <Create title="Thêm danh mục Sản Phẩm" {...props}>
        <SimpleForm>
            <TextInput source="categoryName" label = 'Danh mục Sản Phẩm' validate={required()} />
            <BooleanInput source='status' label="Trạng thái" />
        </SimpleForm>
    </Create>
);