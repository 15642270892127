import * as React from "react";
import {Datagrid, EditButton, FunctionField, List, TextField} from "react-admin";
import Chip from '@material-ui/core/Chip';
import {formatMoney} from "providers/utils";
import PropertyTypeConst from "providers/PropertyTypeConst";

export const CarList = (props) => {
    return (
        <List {...props} bulkActionButtons={false} title='Danh sách xe cho thuê'
              filter={{propertyType: PropertyTypeConst.CAR_FOR_RENT.key}}>
            <Datagrid>
                <EditButton label={""}/>
                <TextField source="propertyTitle" label='Loại xe'/>
                <FunctionField label="Giá thuê" render={record => {
                    if (!record.propertyPrice) {
                        return 'Liên Hệ';
                    }

                    let priceType = record.priceType;
                    if (record.priceTypeCustom) {
                        priceType = record.priceTypeCustom;
                    }
                    return formatMoney(record.propertyPrice) + '/' + priceType;
                }}/>
                <TextField source="seatingCapacity" label='Sô chổ ngồi'/>
                <FunctionField label="Trạng thái" render={record => {
                    return record.status ?
                        <Chip label="Cho thuê" style={{backgroundColor: '#6fbf73', color: '#fff'}}/> :
                        <Chip label="Không cho thuê" style={{backgroundColor: '#ff1744', color: '#fff'}}/>;
                }}/>
            </Datagrid>
        </List>
    )
};

