import React from "react";
import {
    ArrayInput,
    BooleanInput,
    Create,
    FormTab,
    required,
    SaveButton,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
    Toolbar,
} from 'react-admin';
import CancelButton from "custom/CancelButton";
import '@toast-ui/editor/dist/toastui-editor.css';
import {RichTextInput} from "ra-richtext-tiptap";

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '5%'},
    rightCol: {flex: 1, marginLeft: '5%'},
    singleCol: {marginTop: '1em', marginBottom: '1em'},
};


const ToolbarCreate = (props) => {
    return (<Toolbar {...props} >
        <SaveButton label="Lưu"/>
        <CancelButton redirect="list"/>
    </Toolbar>)
}

export const CreateForm = (props) => {
    return (
        <Create {...props} title='Thêm thông tin'>
            <TabbedForm toolbar={<ToolbarCreate/>} redirect="list">
                <FormTab label="Thông tin liên lạc">
                    <div style={styles.flex}>
                        <div style={styles.leftCol}>
                            <TextInput source="title" validate={[required()]} label='Tiêu đề' style={{width: '29rem'}}/>
                        </div>

                        <div style={styles.rightCol}>
                            <TextInput source="hotline" validate={[required()]} label='Hotline'
                                       style={{width: '29.5rem'}}/>
                        </div>
                    </div>

                    <div style={styles.flex}>
                        <div style={styles.leftCol}>
                            <TextInput source="email" validate={[required()]} label='Email' style={{width: '29rem'}}/>
                        </div>

                        <div style={styles.rightCol}>
                            <TextInput source="website" validate={[required()]} label='Website'
                                       style={{width: '29.5rem'}}/>
                        </div>
                    </div>

                    <div style={styles.flex}>
                        <div style={styles.singleCol}>
                            <RichTextInput source="description" style={{width: '29.5rem'}}
                                           validate={[required()]} label='Giới thiệu'/>
                        </div>
                    </div>


                    <ArrayInput source="taydoBranches" label={"Chi nhánh"}>
                        <SimpleFormIterator disableReordering>
                            <TextInput source="address" label={"Địa chỉ"} style={{width: '39rem'}}/>
                            <BooleanInput label="Trụ sở" source="headOffice"/>
                        </SimpleFormIterator>

                    </ArrayInput>

                </FormTab>

                {/*<FormTab label="Giao diện">*/}
                {/*    <div style={styles.flex}>*/}
                {/*        <div style={styles.singleCol}>*/}
                {/*            <TextInput source="homePageTitleFirst" validate={[required()]} label='Tiêu đề'*/}
                {/*                       style={{width: '29rem'}}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*    <div style={styles.flex}>*/}
                {/*        <div style={styles.singleCol}>*/}
                {/*            <div style={{width: '60rem'}}>*/}
                {/*                <ImageInput source="homeBackgroundImgFirst" label="Hình ảnh trang chủ"*/}
                {/*                            style={{width: '60rem'}}*/}
                {/*                            accept="image/*" placeholder={<p>Kéo thả ảnh của bạn vào đây...</p>}*/}
                {/*                            validate={required()} multiple={false}>*/}
                {/*                    <ImageField source="src" title="title"/>*/}
                {/*                </ImageInput>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*    <div style={styles.flex}>*/}
                {/*        <div style={styles.singleCol}>*/}
                {/*            <TextInput source="homePageTitleSecond" validate={[required()]} label='Tiêu đề 2'*/}
                {/*                       style={{width: '29rem'}}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*    <div style={styles.flex}>*/}
                {/*        <div style={styles.singleCol}>*/}
                {/*            <div style={{width: '60rem'}}>*/}
                {/*                <ImageInput source="homeBackgroundImgSecond" label="Hình ảnh"*/}
                {/*                            style={{width: '60rem'}}*/}
                {/*                            accept="image/*" placeholder={<p>Kéo thả ảnh của bạn vào đây...</p>}*/}
                {/*                            validate={required()} multiple={false}>*/}
                {/*                    <ImageField source="src" title="title"/>*/}
                {/*                </ImageInput>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</FormTab>*/}
            </TabbedForm>
        </Create>
    )
}