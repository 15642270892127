import React from "react";
import {Datagrid, EditButton, List, ShowButton, TextField} from "react-admin";

export const ListView = (props) => {
    return (
        <List {...props} bulkActionButtons={false} title="Quản lý thông tin">
            <Datagrid>
                <EditButton label={''}/>
                <ShowButton label={''}/>
                <TextField source="title" label={"Tiêu đề"}/>
                <TextField source="hotline" label={"Hotline"}/>
                <TextField source="email" label={"Email"}/>
                <TextField source="website" label={"Website"}/>
            </Datagrid>
        </List>
    )
}